import React, { useContext, useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import OrangeLine from '../../components/OrangeLine';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import apiRequest from '../../components/ApiRequest';
import { useParams } from 'react-router';
import Loader from '../../components/loader/Loader';
import GlobalContext from '../../components/GlobalContext';

export default function PasswordRecovery() {
  const { t } = useTranslation();
  const { hash } = useParams();
  const [loading, setLoading] = useState(true);
  const { setShowLoader } = useContext(GlobalContext);
  const [message, setMessage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [borderColor, setBorderColor] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    setShowLoader(true);
    apiRequest(
      {
        command: 'PASSWORD_RESET'
      },
      {
        hash
      }
    ).then((response) => {
      setShowLoader(false);
      setLoading(false);
      console.log(response.data);
      setMessage(response.data.message);
      if (response.data.status === 'OK') {
        setBackgroundColor('#dcfce7');
        setBorderColor('#10b981');
        setColor('#16a34a');
      } else if (response.data.status === 'FAIL') {
        setBackgroundColor('#fee2e2');
        setBorderColor('#ef4444');
        setColor('#dc2626');
      } else if (response.data.status === 'EXPIRED') {
        setBackgroundColor('#fef3c7');
        setBorderColor('#fcd34d');
        setColor('#d97706');
      }
    });
  }, []);

  return (
    <Wrapper>
      <div>
        <div className='flights-main-top' style={{ marginTop: 20 }}>
          <OrangeLine />
        </div>
        <h1 className='mb-5'> {t('_PASSWORD_RECOVERY')} </h1>
      </div>
      {!loading && (
        <div
          style={{
            width: '100%',
            textAlign: 'left',
            backgroundColor: backgroundColor,
            padding: '15px 20px',
            borderRadius: 8,
            borderColor: borderColor,
            borderWidth: 1,
            borderStyle: 'solid',
            color: color,
            fontSize: 14
          }}
        >
          {t(message)}
        </div>
      )}
    </Wrapper>
  );
}
