import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import apiRequest from '../../components/ApiRequest';
import '../../scss/Invoice.scss';

export default function Invoice() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const invoiceId = searchParams.get('number');

  const { data } = useQuery(
    ['get_invoice', invoiceId],
    () =>
      apiRequest(
        {
          command: 'get_invoice',
          sessionId: localStorage.getItem('token')
        },
        {
          invoice: invoiceId
        }
      ),
    {
      enabled: !!invoiceId
    }
  );

  useEffect(() => {
    // console.log('Invoice.js - get_customer_info');
  }, []);

  const { data: userInfo } = useQuery('userInfo', () =>
    apiRequest({
      command: 'get_customer_info',
      sessionId: localStorage.getItem('token')
    })
  );

  const invoiceData = data?.data?.result || {};
  console.log(invoiceData);
  const userProfile = userInfo?.data?.result?.profile || {};
  const isLockerInvoice = invoiceId.startsWith('LO-');

  function prepareString(str) {
    return str.replace('<div>', '\n').replace('</div>', '');
  }
  return (
    <div className='div'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='p-10'>
            <div className='d-flex flex-wrap'>
              <div className='clogo'>
                <img src='https://www.usa2georgia.com/img/invoice_logo.png' alt='logo' />
              </div>
              {invoiceData.paid === '1' && (
                <div className='paid-thumb'>
                  <img src='https://www.usa2georgia.com/img/invoice_paid.jpg' alt='paid' />
                </div>
              )}
              <div className='ms-auto text-right'>
                <div>{invoiceData.date}</div>
                <div className='font-medium'>
                  {t('_INVOICE')}:{' '}
                  <span className='text-danger' id='invoice_number2'>
                    {invoiceId}
                  </span>
                </div>
                {!isLockerInvoice && (
                  <>
                    <div>
                      {t('_HAWB')}: <span className='text-danger'> {invoiceData.hawb}</span>
                    </div>
                    <div>
                      {t('_WEIGHT')}: {invoiceData.weight_lbs} lbs – {invoiceData.weight_kg} კგ
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 m-t-40'>
                <span className='font-medium'>{t('_CUSTOMER')}</span>
                <br />
                {userProfile.is_organization ? userProfile.organization : `${userProfile.first_name_en} ${userProfile.last_name_en}`}
                <br />
                {userProfile.mobile}
                <br />
                {userProfile.email}
                <br />
                {t('_SUITE')} {userProfile.suite}
              </div>
              <div className='col-md-6 m-t-40'>
                <span className='font-medium'>{t('_USA2GEO_COMPANY_NAME')}</span>
                <br />
                {t('_USAGEO_ADDRESS')}
                <br />
                {t('_CUSTOMER_SUPPORT')}: (+995 32) 2-42-18-18
                <br />
                sales@usa2georgia.com
                <br />
                www.usa2georgia.com
              </div>
            </div>
            <div className='table-responsive'>
              <table className='table table-bordered m-t-40 invoice-table'>
                <tbody>
                  <tr>
                    <th className='text-align-left'>{t('_INVOICE')}</th>
                    <th>{t('_AMOUNT')}</th>
                    <th>{t('_VAT')}</th>
                    <th>{t('_SUM')}</th>
                  </tr>
                  {(invoiceData?.data || []).map((item, index) => (
                    <tr key={index}>
                      <td className='text-align-left'>
                        {prepareString(t(item?.key))}:
                        {/* {
                            item.key === '_INSURANCE' && prepareString(invoiceData?.insured_trackings)
                          } */}
                        {item.key === '_INSURANCE' && <p dangerouslySetInnerHTML={{ __html: invoiceData?.insured_trackings }} />}
                        {item.key === '_CUT_DOWN_FEE' && <p dangerouslySetInnerHTML={{ __html: invoiceData?.penalty_types?.CUT_DOWN_FEE?.trackings.join('<br />') }} />}
                        {item.key === '_PENALTY' && `(${invoiceData?.penalty_day_count} ${t('_DAYS')})`}
                      </td>
                      {typeof item?.value === 'object' ? (
                        <>
                          <td style={{ color: 'red' }}>{parseFloat(item?.value?.amount).toFixed(2)} ₾</td>
                          <td style={{ color: 'red' }}>{parseFloat(item?.value?.vat).toFixed(2)} ₾</td>
                          <td style={{ color: 'red' }}>{parseFloat(item?.value?.total).toFixed(2)} ₾</td>
                        </>
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                          <td>{item?.value.toFixed(2)} ₾</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              {invoiceData.private === 'B' && (
                <div>
                  <div>
                    <strong style={{ fontFamily: 'HelveticaBoldCaps' }}>{t('_BANK_PARAMETERS')}</strong>
                  </div>
                  <div>
                    <strong>{t('_BANK_PARAMETER_LINEA')}</strong>
                  </div>
                  <div>
                    <strong>{t('_BANK_PARAMETER_LINEB')}</strong>
                  </div>
                  <div>
                    <strong>{t('_BANK_PARAMETER_LINEC')}</strong>
                  </div>
                  <div>
                    <strong>{t('_BANK_PARAMETER_LINED')}</strong>
                  </div>
                  <div>
                    <strong>{t('_BANK_PARAMETER_LINEE')}</strong>
                  </div>
                  <div>
                    <strong>{t('_BANK_PARAMETER_LINEF')}</strong>
                  </div>
                  <div>
                    <strong>{t('_BANK_PARAMETER_LINEG')}</strong>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
