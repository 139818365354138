// hooks
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

// components
import SettingsItem from './components/SettingsItem';
import apiRequest from '../../components/ApiRequest';
import GlobalContext from '../../components/GlobalContext';
import Wrapper from '../../components/Wrapper';

// assets
import { BranchesLocationBlackIcon, CreditCardIcon, CutdownIcon, NotificationSettings, PasswordIcon2, ProfileIcon, Truck2Icon } from '../../assets/icons';

const Settings = () => {
  const { t } = useTranslation();
  const { setShowLoader } = useContext(GlobalContext);

  useEffect(() => {
    // console.log('Settings.js - get_customer_info');
  }, []);

  const {
    data: getSettings,
    isLoading,
    isSuccess
  } = useQuery('getCustomerSettings', () =>
    apiRequest({
      command: 'get_customer_settings',
      sessionId: localStorage.getItem('token')
    })
  );

  const { data: getCards, isLoading: cardsIsLoading } = useQuery('getCards', () =>
    apiRequest(
      {
        command: 'get_customer_cards',
        sessionId: localStorage.getItem('token'),
        secret: '840063F030-A1BDC8-48BFEB-B9AA-13D55403'
      },
      {
        secret: '840063F030-A1BDC8-48BFEB-B9AA-13D55403'
      }
    )
  );

  const { data: userData } = useQuery(
    'userInfo',
    () =>
      apiRequest({
        command: 'get_customer_info',
        sessionId: localStorage.getItem('token')
      }),
    {
      enabled: !!localStorage.getItem('token')
    }
  );

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  const settings = isSuccess && getSettings.data.result;
  const branch = settings && settings.data.branch_settings.default_branch;
  const notifications = settings && settings.notifications;
  const locker = settings && settings.data.locker_info;
  const profile = (settings && settings.data.profile) || [];
  const cards = !cardsIsLoading && getCards.data.result;

  return (
    <Wrapper>
      <div className='settings-container pb-5'>
        <div className='pt-5'>
          <h1 className='mb-4'>{t('_SETTINGS')}</h1>
          <div className='pt-2'>
            <SettingsItem title={t('_VIEW_PROFILE')} icon={<ProfileIcon />} item={profile} />
            <SettingsItem title={t('_CHANGE_PASSWORD')} icon={<PasswordIcon2 />} />
            <SettingsItem title={t('_NOTIFICATIONS')} icon={<NotificationSettings />} item={notifications} />
            <SettingsItem title={t('_SAVED_CARDS')} icon={<CreditCardIcon />} item={cards} />
            <br />
            <SettingsItem title={t('_CUTDOWN_SETTINGS')} icon={<CutdownIcon />} item={userData} />
            <br />
            <SettingsItem title={t('_select_branch_or_locker')} icon={<BranchesLocationBlackIcon />} branch={branch} locker={locker} />
            <br />
            <SettingsItem title={t('_CHINA_TRUCK_TRANSPORTATION_MENU')} icon={<Truck2Icon width={24} />} item={userData} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Settings;
